import './Nav.css';
import Logo from '../../assets/Logo.png';
import Menu from '../../assets/menu.svg'
import { useState ,useEffect,useRef} from 'react';
export default function Nav({setShowLogin,user,handleSignout}){
    const [open,setOpen]=useState(null);
    const [dropdown,setDropdown]=useState(false);
    const dropdownRef = useRef(null);
    const handleToggle = ()=>{
        setDropdown(!dropdown);
    }

    useEffect(()=>{
        window.addEventListener('click',(event)=>{
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdown(false);
            }
        },false);
    },[]);

return(
    <div className='nav'>
        <img src={Logo} className='logo' alt=''/>
        <img src={Menu} alt=""  className='navbtn' onClick={()=>{setOpen(open=>!open)}}/>
        <div className={open?'nav_links open':'nav_links'}>
            <a className='link' href='https://www.dressmeofficial.com/'>HOME</a>
            {user?
            <div className="nav-dropdown" ref={dropdownRef}>
                <img className='avatar' src={user.photoURL} onClick={handleToggle} alt={user.email[0]}/>
                <div className="dropdown-content" style={{display:dropdown?'flex':'none'}}>
                    <div className="dropdown-header">{user.email}</div>
                    <span className="dropdown-button" onClick={handleSignout}>signout</span>
                </div>
            </div>
            :
            <div className="button" onClick={()=>{setShowLogin(true)}}>Signin</div>
            }
        </div>
    </div>
)
}
