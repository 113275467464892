import './Hero.css'
import heroImage from '../../assets/hero_image.png';
import heroImageMini from '../../assets/hero_image_mini.png';
export default function Hero(){
    const handleGetStarted =()=>{
        let target = document.querySelector('.main-section');
        target.scrollIntoView({behavior: "smooth", block: "center"});
    }
return(
    <div className="hero">
        <div className="hero_content">
            <h1>EXPLAINABLE FOOTWEAR<br/>RECOMMENDATIONS</h1>
            <p>Explore our wide range of footwear recommendations meticulously selected to match your clothing style, body type, and preferred colors, ensuring every step you take is a true reflection of your unique fashion sense.</p>
            <div className="button" onClick={handleGetStarted}>Get Started</div>
        </div>
        <img src={heroImage} alt="" className="hero_image" />
        <img src={heroImageMini} alt="" className="hero_image_mini" />
    </div>
)
}
