// import {FirebaseOptions, initializeApp} from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import {connectDatabaseEmulator, getDatabase} from "@firebase/database";
import {initializeApp} from 'firebase/app'
import {getFirestore} from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyDq0syz-9WRzDQq0rBTZNGBYqIHYVHSNCg",
  authDomain: "quizzer-a4146.firebaseapp.com",
  databaseURL: "https://quizzer-a4146-default-rtdb.firebaseio.com",
  projectId: "quizzer-a4146",
  storageBucket: "quizzer-a4146.appspot.com",
  messagingSenderId: "610180603080",
  appId: "1:610180603080:web:d48cae539f2aa8ebefd9fd",
  measurementId: "G-0VQYTN7TKK"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database =  getFirestore(app);



export default app;
