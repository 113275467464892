import './ImageSelection.css'
import galleryIcon from '../../assets/gallery_icon.svg' 
import example from '../../assets/example.png'
import example1 from '../../assets/example2.jpg'
import example3 from '../../assets/image (2).png'
import { useEffect, useState } from 'react';
export default function ImageSelection({setFile}){
    const [dragover,setDragover]=useState(false);
        
    const handleBrowse=()=>{
        let input = document.createElement('input');
        input.type='file';
        input.onchange=()=>{
            if(input.files[0].type.substring(0,5)=='image' && input.files[0].size<15000000){
                console.log(input.files[0]);
                setFile(input.files[0]);
            }
        }
        input.click();
    }
    
    const handleDrop=(event)=>{
        event.preventDefault();
        setFile(false);
        if(event.dataTransfer.files[0].type.substring(0,5)=='image' && event.dataTransfer.files[0].size<15000000){
            setFile(event.dataTransfer.files[0]);
        }
    };


return(
<div className='image_selection'>
        <div
            className={dragover?"drop_container active":"drop_container"}
            onDragOver={(event)=>{
                event.preventDefault();
                setDragover(true);
            }}
            onDragLeave={(event)=>{
                event.preventDefault();
                setDragover(false);
            }}
            onDrop={(event)=>{
                handleDrop(event);
                setDragover(false);
            }}
            >
            <img src={galleryIcon} alt="" />
            <div className='draganddrop_text'>
                Drag And Drop Your Image Or
                <div className="button" onClick={handleBrowse}>Browse</div>
            </div>
        </div>
        <div className="example_container">
        Or click on an example below
        <div className="examples">
            <img src={example3} alt="" className="example" />
            <img src={example1} alt="" className="example" />
        </div>
        </div>
</div>
)
}