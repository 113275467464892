import Hero from "../components/Hero/Hero";
import Nav from "../components/nav/Nav";
import Footer from "../components/Footer/Footer";
import './Home.css';
import {useEffect, useState} from 'react'
import firebase from '../firebase';
import {getAuth,setPersistence,browserLocalPersistence} from 'firebase/auth'
import Login from "../components/Login/Login";
import MainSection from '../components/MainSection/MainSection'
export default function Home(){
    const [selected,setSelected]= useState(null);
    const [user,setUser]= useState(null);
    const [showLogin,setShowLogin]= useState(false);

    
    const auth =getAuth(firebase);
    setPersistence(auth,browserLocalPersistence);

    useEffect(()=>{
        auth.onAuthStateChanged((usr)=>{
            if(usr){
                setUser(usr);
            }
        });
    },[])
    const  handleSignout=async ()=>{
        await auth.signOut();
        setUser(auth.currentUser);
    }


return(
    <div className="home">
        {showLogin?<Login onClose={()=>{setShowLogin(false)}} setUser={setUser}/>:<></>}
        <Nav setShowLogin={setShowLogin} user={user} handleSignout={handleSignout}/>
        <Hero/>
        <MainSection setShowLogin={setShowLogin}/>
        <Footer/>
    </div>
)
}