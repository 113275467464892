import React, { useEffect, useState } from "react";
import firebase from "../../firebase";
import {GoogleAuthProvider,signInWithPopup,getAuth,signInWithEmailAndPassword,createUserWithEmailAndPassword} from 'firebase/auth'
import {doc , setDoc} from "firebase/firestore";
import { auth, database} from "../../firebase";
import "./Login.css";
import googleIcon from '../../assets/google.svg'
import closeIcon from '../../assets/close.svg'
const Login = ({ onClose , setUser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword,setConfirmPassword]=useState("");
  const [isSingup,setIsSingup]=useState(false);
  const [errorMessage,setErrorMessage]=useState(null);
  useEffect(()=>{
    if(errorMessage){
      setTimeout(()=>{
        setErrorMessage();
      },3000)
    }
  },[errorMessage])
  const auth =getAuth(firebase);
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleEmailLogin = async (event) => {
    if(email.length && password.length){
      try{

        event.preventDefault();
        await signInWithEmailAndPassword(auth,email,password);
        setUser(auth.currentUser);
        onClose();
      }catch(e){
      setErrorMessage('Something Went Wrong !')
      }
    }else{
      setErrorMessage('Invalid Input !')
    }
};
const handleEmailSignup = async (event) => {
  if(confirmPassword === password){
    try{
      await createUserWithEmailAndPassword(auth,email,password);
      setUser(auth.currentUser);
      const user = auth.currentUser;
        const userRef = doc(database, "users", user.uid);
        await setDoc(userRef, {
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            userUploadCycle: 0,
        });
      onClose();
    }catch(e){
      setErrorMessage('Something Went Wrong !');
    }
  }else{
    setErrorMessage('Invalid Input !');
  }

}
const handleGoogleLogin = async (event) => {
    event.preventDefault();
    let googleProvider = new GoogleAuthProvider();
    await signInWithPopup(auth,googleProvider);
    setUser(auth.currentUser);
    const user = auth.currentUser;
    const userRef = doc(database, "users", user.uid);
    await setDoc(userRef, {
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        userUploadCycle: 0,
    });
    onClose();
  };

  return (
    <div className="backdrop">
        <div className="login-container">
            <img className="close-button" src={closeIcon} alt="" onClick={onClose}/>
        <h2 className="login-title">Signin</h2>
            <div className="login-input">
                <input type="text" onChange={handleEmailChange} placeholder=""/>
                <label>Email</label>
            </div>
            <div className="login-input">
                <input type="password" onChange={handlePasswordChange} placeholder=""/>
                <label >Password</label>
            </div>
            {isSingup?<>
          
              <div className="login-input">
                <input type="password" onChange={handleConfirmPasswordChange} placeholder=""/>
                <label>Confirm Password</label>
            </div>

              <div className="button" onClick={handleEmailSignup}>Signup</div>
            <div className="login-error">{errorMessage}</div>
              <div>
            Already have an account ?
              <span className="login-link" onClick={()=>{setIsSingup(false)}}> Login</span>
            </div>

            </>:<>
            <div className="button" onClick={handleEmailLogin}>Login</div>
            <div className="login-error">{errorMessage}</div>
              <div>
              Dont have an account ?
              <span className="login-link" onClick={()=>{setIsSingup(true)}}> Signup</span>
              </div>
            </>}
            <div onClick={handleGoogleLogin} className="button light">
                <img src={googleIcon}/>
                Continue with Google</div>
        </div>
    </div>
  );
};

export default Login;
