import './Footer.css'
import LinkedIn from '../../assets/LinkedIn.svg'
import Twitter from '../../assets/Twitter.svg'
import Instagram from '../../assets/instagram.svg'
import Discord from '../../assets/Discord.svg'
export default function Footer(){
return(
    <div className="footer">
        <div className="link_group">
            <h3>Social</h3>
            <a href="https://www.linkedin.com/company/dressmeofficial">
                <img src={LinkedIn} alt=""/>
                LinkedIn
            </a>
        </div>
        <div className="link_group">
            <h3>Legal</h3>
            <a href="https://www.dressmeofficial.com/Privacy">Policy</a>
            <a href="https://www.dressmeofficial.com/Privacy">Term Of Use</a>
            <a href="https://www.dressmeofficial.com/Privacy">Cookie Policy</a>
        </div>
        <div className="link_group">
            <h3>Support</h3>
            <a href="mailto:hello@dressmeofficial.com">Contact Us</a>
            <a href="mailto:hello@dressmeofficial.com">Help</a>
        </div>
    </div>
)
}
