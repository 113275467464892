/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:46f2d317-17af-4a82-895d-f1d29227d589",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_6g2yDnnC8",
    "aws_user_pools_web_client_id": "1sd98tajff03idfqb3t2hpgp84",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "shoesrecommendationwf75dd30254fe47bea4a7ee51d6a224710-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
