import {useEffect, useRef, useState} from 'react'
import './MainSection.css'
import closeIcon from '../../assets/close.svg'
import ImageSelection from '../ImageSelection/ImageSelection';
import loadingIcon from '../../assets/loading.gif'
import uploadIcon from '../../assets/upload.svg'
import firebase, {database} from '../../firebase';
import {getAuth} from 'firebase/auth'
import {Storage} from "aws-amplify";
import {doc, getDoc, updateDoc} from 'firebase/firestore';
import Card from '../Card/Card';

export default function MainSection({setShowLogin}) {
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState({});
  const [result, setResult] = useState([]);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [s3Uploaded, setS3Uploaded] = useState(false);
  const [lastFetchedDescription, setLastFetchedDescription] = useState(null);
  const [lastFetchedRecommendation, setLastFetchedRecommendation] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [recommendation, setRecommendation] = useState([])
  const timeoutIdRef = useRef(0)
  const resultStatus = useRef({
    descriptionLoaded: false,
    recommendationLoaded: false
  })


  const auth = getAuth(firebase);
  useEffect(() => {
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPreview(reader.result);
      }
    } else {
      setPreview(null);
      // setResult(null);
      // setDescription(null);
      setLoading(false);
    }
  }, [file]);


  const uploadToS3 = async (file) => {
    try {
      const userId = auth.currentUser?.uid;
      setLoading(true);
      if (userId) {
        setLoading(true);
        const userRef = doc(database, 'users', userId);
        const userDoc = await getDoc(userRef);
        const userUploadCycle = userDoc.get('userUploadCycle') || 0;
        const filename = `${userId}/${userUploadCycle + 1}/images/file.jpg`
        const result = await Storage.put(filename, file, {
          contentType: file.type,
        });
        console.log('Image uploaded:', result);
        await updateDoc(userRef, {
          userUploadCycle: userUploadCycle + 1,
        });
        const uid = auth.currentUser?.uid;
        console.log(uid);
        const resultFetch = await Storage.list(`${uid}/${userUploadCycle}/results`);
        console.log(resultFetch);
        if (!resultFetch || !resultFetch.results || resultFetch.results.length === 0) {
          return;
        }
        const urls = await Promise.all(
            resultFetch.results.map(async (result) => {
              return await Storage.get(result.key);
            })
        );
        setResult(urls);
        console.log(urls);
        setS3Uploaded(true)
        await fetchDescriptions() ;
        return result.key;
      } else {
        setShowLogin(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetcher =  async (jsonFiles) => {
    for (const item of jsonFiles) {
      const url = await Storage.get(item.key);
      const response = await fetch(url);
      const text = await response.text();
      const resJSON = JSON.parse(text);
      if (item.key.includes('result_description')) {

        setRecommendation(_recommendation => _recommendation.concat(resJSON))
        console.log({"recommendation" : recommendation}) ;
      }
      else setDescription(prevState =>({
        ...prevState,
        ...resJSON
      }))
    }
  }



  const fetchDescriptions= async () => {
    try {
      if (resultStatus.current.descriptionLoaded && resultStatus.current.recommendationLoaded) {
        setLoading(false);
        clearTimeout(timeoutIdRef.current)
        resultStatus.current = {
          descriptionLoaded: false,
          recommendationLoaded: false,
        }
        timeoutIdRef.current = 0
      }
      const uid = auth.currentUser?.uid;
      const userRef = doc(database, 'users', uid);
      const userDoc = await getDoc(userRef);
      const userUploadCycle = userDoc.get('userUploadCycle') || 0;
      console.log({userUploadCycle})
      if (!resultStatus.current.descriptionLoaded) {
        const result = await Storage.list(`${uid}/${userUploadCycle}/description`, {
          startAfter: lastFetchedDescription,
        });
        await fetcher(result.results);
        if (result.results.length) {
          resultStatus.current.descriptionLoaded = true;
          setLastFetchedDescription(result.results[result.results.length - 1].key);
        }
      }
      if (!resultStatus.current.recommendationLoaded) {
        const result = await Storage.list(`${uid}/${userUploadCycle}/result_description`, {
          startAfter: lastFetchedRecommendation,
        });
        await fetcher(result.results);
        if (result.results.length) {
          resultStatus.current.recommendationLoaded = true;
          setLastFetchedRecommendation(result.results[result.results.length - 1].key);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (s3Uploaded)
      timeoutIdRef.current = setInterval(() => {
        fetchDescriptions()
      }, 6000)
  }, [s3Uploaded])

  const uniqueRecommendations = recommendation?.reduce((uniqueArr, value) => {
    const isDuplicate = uniqueArr.some((item) => item.image_url === value.image_url);
    if (!isDuplicate) {
      uniqueArr.push(value);
    }
    return uniqueArr;
  }, []);
  return (
      <div className="main-section" >
        {/*{JSON.stringify(description)}*/}
        {/*{JSON.stringify(recommendation)}*/}
        {file ? (
            <>
              <div className="preview">
                <img
                    className="close-button"
                    src={closeIcon}
                    alt=""
                    onClick={() => {
                      setFile(null);
                      console.log("button closed");
                      setIsFetching(false);
                      clearTimeout(timeoutIdRef.current)
                      resultStatus.current = {
                        descriptionLoaded: false,
                        recommendationLoaded: false,
                      }
                      timeoutIdRef.current = 0
                      window.location.reload();
                      setDescription([])
                      setRecommendation([])
                    }}
                />
                <img className="preview-image" src={preview} alt=""/>
                {Object.keys(description).length ? null : <div
                    className="button-orange"
                    onClick={async () => {
                      setIsFetching(true);
                      await uploadToS3(file);
                      setIsFetching(false);
                    }}
                >
                  {loading ? (
                      <img src={loadingIcon} alt="Loading" className="loading-icon"/>
                  ) : (
                      <>
                        <img src={uploadIcon} alt="Upload"/>
                        Upload
                      </>
                  )}
                </div>
                }
              </div>
              { Object.keys(description)?.length  ? (
                  <div className="result-container">
                    <div className="message">
                      <p>
                        Processing
                        <img
                            src={loadingIcon}
                            alt="loading..."
                            className="loading-icon"
                        />
                      </p>
                    </div>
                    <div className="message"> {description?.outfit_type? description?.outfit_type : "Loading..."}</div>
                    <div className="message">
                      Look s like You Have These Colors On Your Top
                      <div className="color-palette">
                        {Object.keys(description)?.length  ? description?.topcolorpallet?.map(
                            (color, i) => {
                              return (
                                  <div
                                      key={i}
                                      className="color"
                                      style={{background: color}}
                                  ></div>
                              );
                            }
                        ) : null }
                      </div>
                    </div>
                    {description?.bottomcolorpallet?.length && <div className="message">
                      And These Colors On Your Bottom
                      <div className="color-palette">
                        {description?.bottomcolorpallet ? description?.bottomcolorpallet?.map(
                            (color, i) => {
                              return (
                                  <div
                                      key={i}
                                      className="color"
                                      style={{background: color}}
                                  ></div>
                              );
                            }
                        ) : null}
                      </div>
                    </div>}
                    <div className="message">
                      <p>
                        Let's Find You A Matching Footwear
                        <img
                            src={loadingIcon}
                            alt=""
                            className="loading-icon"
                        />
                      </p>
                    </div>
                    {Object.keys(description)?.length && uniqueRecommendations?.length ? (
                        uniqueRecommendations?.map((value, index) => {
                          return <Card content={value} key={index} />;
                        })
                    ) : (
                        <></>
                    )}

                    {/*{recommendation.length ? (*/}
                    {/*    <div className="button orange">*/}
                    {/*      Show Me Another One*/}
                    {/*    </div>*/}
                    {/*) : (*/}
                    {/*    <></>*/}
                    {/*)}*/}
                  </div>
              ) : null}
            </>
        ) : (
            <ImageSelection setFile={setFile}/>
        )}
      </div>
  );
}