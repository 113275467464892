import './Card.css';
import leftIcon from '../../assets/left.svg'
import rightIcon from '../../assets/right.svg'
export default function Card({content,result, handleLeftClick,handleRightClick}){
    console.log(content) ;
    console.log(result)
return(

<div className="card">
    <p className='card-paragraph'>“{content.explanation}”</p>
    <img loading={'lazy'} className='card-image' src={content.image_url} alt={content['footwear-type']} width={'100%'} height={'100%'} />
    <div className="card-footer">
        <div className="card-footer">
            <h3 className='card-title'>{content['footwear-type']}</h3>
            <a href={content['myntra-link']} className='card-link'>Get From Myntra</a>
        </div>
    </div>
</div>
)
}